.new_asset_model .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #ef0855;
}

.select-text-dark {
  color: #000 !important;
}

.select-text-dark:hover {
  color: #ffffff !important;
}


/* To increase the width of video */
.player-wrapper {
  width: auto;
  height: auto;
}

.react-player {

  position: relative;
}

.react-player>div {
  position: absolute;
}

/* Event Theme Color Css */

.eventtheme_input_style {
  width: 200px;
  padding: 5px;
}

.eventtheme_input_style span>input[type="color"] {
  padding: 0px;

  width: 36px;
  height: 25px;
  border: none;
}

.eventtheme_padding_left {
  padding-left: 14.7%;
}

/* Event Settings Css */

.eventsettings_padding_left {
  padding-left: 14.7%;
}



.eventsettings_table tr {
  height: 65px;
}

.eventsettings_fileuload_style {
  width: 150px;
}